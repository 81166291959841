
.mx-5 {
    margin-left: ($spacer * 1.25) !important;
    margin-right: ($spacer * 1.25) !important;
}

.my-5 {
    margin-top: ($spacer * 1.75) !important;
    margin-bottom: ($spacer * 1.75) !important;
}

.my-6 {
    margin-top: ($spacer * 2) !important;
    margin-bottom: ($spacer * 2) !important;
}

.my-7 {
    margin-top: ($spacer * 2.25) !important;
    margin-bottom: ($spacer * 2.25) !important;
}

.text-padded {
    padding: 1.25rem;
}

.navbar-brand > img, .footer-img {
    height: auto;
    @media (max-width: 766px) {
        width: 200px;
    }
    @media (min-width: 767px) { 
        width: 340px;
    }
}

.jumbotron {
    @media (min-width: 767px) {
        //background-color: white;
        padding-top: ($spacer * 5);
        padding-bottom: ($spacer * 5);
    }
}

.card {
    border: none;
}

.footer-logo {
    height: auto;
    @media (max-width: 766px) {
        width: 150px;
    }
    @media (min-width: 767px) { 
        width: 300px;
    }
}

.social-icon {
    height: 40px;
    width: 40px;
}

.main-page img.card-img-top {
    @media (min-width: 767px) {
        width: 350px;
    }
    height: 250px;
}

.about-page img {
    width: 100%;
    @media (max-width: 766px) {
        height: 250px;
    }
    @media (min-width: 767px) {
        height: 350px;
    }
}
.service-deck img.card-img-top {
    height: 300px;
}
.service-header .card-header {
}

.service-img {
    @media (max-width: 766px) {
        height: 250px;
    }
    @media (min-width: 767px) {
        height: 350px;
    }
}

.btn-green {
    color: #fff;
    background-color: $color-primary-0;
    border-color: $color-primary-0;
    @extend .py-2, .px-5, .m-2; 
    &:hover {
        color: $color-primary-0;
        background-color: rgba(0,0,0,0.03);
    }
}

.btn-opaque {
    color: #fff;
    background-color: $color-primary-b0;
    border-color: $color-primary-b0;
    @extend .py-2, .px-5, .m-2; 
    &:hover {
        color: $color-primary-b0;
        background-color: rgba(0,0,0,0.03);
    }
}

footer .nav-link {
    color: rgba(0,0,0,0.5);
}
