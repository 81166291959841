$color-primary-0: #3AB549;	// Main Primary color */
$color-primary-1: #8ADF95;
$color-primary-2: #5CCA69;
$color-primary-3: #19A82B;
$color-primary-4: #0C811B;

// As RGBa codes */

$rgba-primary-0: rgba( 58,181, 73,1);	// Main Primary color */
$rgba-primary-1: rgba(138,223,149,1);
$rgba-primary-2: rgba( 92,202,105,1);
$rgba-primary-3: rgba( 25,168, 43,1);
$rgba-primary-4: rgba( 12,129, 27,1);

// As hex codes */

$color-primary-b0: #3A2313;	// Main Primary color */
$color-primary-b1: #665141;
$color-primary-b2: #4D3524;
$color-primary-b3: #271407;
$color-primary-b4: #140800;

// As RGBa codes */

$rgba-primary-b0: rgba( 58, 35, 19,1);	// Main Primary color */
$rgba-primary-b1: rgba(102, 81, 65,1);
$rgba-primary-b2: rgba( 77, 53, 36,1);
$rgba-primary-b3: rgba( 39, 20,  7,1);
$rgba-primary-b4: rgba( 20,  8,  0,1);


body {
    display: block;
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    margin: 0;
}

main {
    @extend .py-4;
}

//footer {
//    color: white;
//    background-color: $color-primary-b3;
//    border-color: $color-primary-b3;
//}
